<script setup lang="ts">
const workspaceStore = useWorkspaceStore();
const isBalanceHidden = ref(false);

function toggleBalanceVisibility(): void {
  isBalanceHidden.value = !isBalanceHidden.value;
}
</script>

<template>
  <div class="profile-popover-balance-overview">
    <div class="profile-popover-balance-header">
      Balance overview
      <Icon
          :name="`lucide:${isBalanceHidden ? 'eye-off' : 'eye'}`"
          @click="toggleBalanceVisibility"
      />
    </div>
    <div class="profile-popover-balance-value">
      <template v-if="isBalanceHidden">****** {{ workspaceStore.accountBalance.currency }}</template>
      <template v-else class="profile-popover-balance-value">{{ workspaceStore.accountBalance.value }}
        {{ workspaceStore.accountBalance.currency }}
      </template>
    </div>
  </div>
</template>

<style scoped>
.profile-popover-balance-overview {
  display: flex;
  gap: var(--tw-size-2);
  flex-direction: column;
  text-wrap: nowrap;
}

.profile-popover-balance-header {
  color: var(--text-secondary-color);
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.profile-popover-balance-value {
  font-size: var(--tw-font-size-2xl);
  font-weight: var(--tw-font-weight-semibold);
}

.theme-toggle-button {
  margin-inline-start: auto;
}
</style>
<script setup lang="ts">
import {PopoverClose, PopoverContent, PopoverRoot, PopoverTrigger} from 'radix-vue'
import {UIButton, UIToolbarToggleButton} from "#components";

const {signOut} = useAuth();

async function onManageAccountClick(): Promise<void> {
  await navigateTo('/profile');
}
</script>

<template>
  <PopoverRoot>
    <PopoverTrigger class="profile-popover-button" :as="UIToolbarToggleButton">
      <Icon name="lucide:user"/>
    </PopoverTrigger>

    <PopoverContent avoid-collisions align="end" side="bottom" :side-offset="4">
      <div class="profile-popover">
        <ProfileOverview/>
        <PopoverClose
            :as="UIButton" full-width @click="onManageAccountClick">
          Manage Account
        </PopoverClose>
        <UIButton full-width @click="signOut({callbackUrl: '/auth/login'})">Logout</UIButton>
      </div>
    </PopoverContent>
  </PopoverRoot>
</template>

<style scoped>
.profile-popover {
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: var(--tw-size-4);
  border: 1px solid var(--border-color);
  border-radius: var(--tw-border-radius-md);
  box-shadow: var(--shadow-l);
  background: var(--Fon__Card);
}
</style>
